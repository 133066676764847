.main {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.header {
  margin: auto;
  padding: 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(101, 171, 185, 0.991);
  border-radius: 40px;
  border-width: 5px;
  border-color: aquamarine;
  box-shadow: 5px 5px 5px rgb(7, 196, 186);
  border-style: solid;
  box-sizing: border-box;
  position: absolute;
  left: 1px;
  top: 1px;
  z-index: 20;
}

.menuButton {
  margin: 0px;
  padding: 5px;
  width: 100%;
  height: auto;
  border-radius: 60px;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 25px;
  font-weight: 900;
  color: rgba(4, 50, 57, 0.991);
  border-width: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.menuitem {
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0;
  width: 100%;
  height: auto;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 21px;
  font-weight: 900;
  color: rgba(4, 50, 57, 0.991);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-style: dotted;
  border-color: rgb(27, 43, 37);
  cursor: pointer;
}
.menu {
  margin: auto;
  padding: 0;
  width: 96%;
  height: auto;
  border-radius: 40px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menulist {
  margin: 0;
  padding: 0;
  width: 96%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
}

@media screen and (max-width: 750px) {
  .menuButton {
    padding: 5px;
    font-size: 15px;
    font-weight: 900;
  }
  .menuitem {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 15px;
  }
}
@media screen and (max-width: 400px) {
  .menuButton {
    padding: 5px;
    font-size: 10px;
    font-weight: 900;
  }
  .menuitem {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 10px;
  }
}
