.main {
  margin: auto;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text {
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 20px;
  text-align: center;
  color: rgb(137, 230, 199);
}
.button {
  width: 200px;
  height: auto;
  margin: 0;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-color: transparent;
  background-color: transparent;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 20px;
  text-align: center;
  color: rgb(137, 230, 199);
}
.header {
  margin: auto;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 50;
}
