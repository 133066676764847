.main {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
}

.active {
  width: 80%;
  height: auto;
  margin: 20px;
  padding: 20px;
  background-color: rgba(101, 171, 185, 0.991);
  border-radius: 60px;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 25px;
  font-weight: 900;
  color: rgba(4, 50, 57, 0.991);
  border-width: 10px;
  border-color: aquamarine;
  border-style: solid;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.active:hover {
  box-shadow: 7px 7px 7px rgb(7, 196, 186);
  color: rgb(19, 85, 82);
}

.team {
  margin: 0;
  padding: 0;
  width: 90%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .active {
    font-size: 15px;
    font-weight: 900;
  }

  .active {
    border-width: 4px;
  }
}

@media screen and (max-width: 450px) {
  .team {
    margin: 0;
    padding: 0;
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
}
