.main {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
}
.header {
  margin: 0;
  margin-left: 10px;
  padding: 0;
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.but {
  animation: glowing 1300ms infinite;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 30px;
  font-weight: 900;
}

.but_t {
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 20px;
  font-weight: 900;
  overflow: hidden;
  box-shadow: 5px 5px 5px #0bcfcf;
}
.butV {
  text-decoration: none;
}
.listprav {
  text-decoration: none;
}

.mb {
  margin-bottom: 40px;
}

.but_2 {
  width: 200px;
  height: 60px;
  margin: 5px;
  padding: 5px;
  background-color: rgba(221, 146, 8, 0.991);
  border-radius: 60px;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 25px;
  font-weight: 900;
  color: rgba(4, 50, 57, 0.991);
  border-width: 10px;
  border-color: rgb(255, 210, 127);
  border-style: solid;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 5px rgb(228, 186, 70);
}

.grammatica_1 {
  margin: 5px;
  margin-bottom: 10px;
  padding: 7px;
  width: calc(100vw * 0.95);
  border-radius: 40px;
  border-width: 5px;
  border-color: aquamarine;
  border-style: solid;
  display: grid;
  justify-content: center;
  grid-template-columns: 20% 58% 20%;
  grid-template-rows: repeat(7, max-content);
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 30px;
  font-weight: 900;
}

.shab {
  margin: 10px;
  margin-left: 0;
  margin-bottom: 20px;
  padding: 5px;
  width: 98%;
  height: auto;
  background-color: rgba(101, 171, 185, 0.991);
  border-radius: 100px;
  border-width: 5px;
  border-color: aquamarine;
  border-style: solid;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  color: rgba(4, 50, 57, 0.991);
}

.verbo:hover {
  background-color: rgb(5, 58, 87);
  box-shadow: 10px 10px 10px rgb(7, 196, 186);
  color: rgb(7, 196, 186);
}

.pr {
  background-color: rgba(209, 124, 198, 0.991);
}
.tl {
  background-color: rgba(228, 160, 35, 0.991);
}

.link {
  background-color: rgba(228, 160, 35, 0.991);
  text-decoration: none;
}
.link:hover {
  background-color: rgb(5, 58, 87);
  box-shadow: 10px 10px 10px rgb(7, 196, 186);
  color: rgb(7, 196, 186);
}
.list {
  width: 300px;
  height: 100%;
  display: flex;
  position: fixed;
  margin-right: 10px;
  margin: 0;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: scroll;
  overflow-x: hidden;
  background-color: rgba(4, 50, 57, 0.991);
  animation-name: move;
  animation-duration: 0.3s;
  z-index: 100;
  top: 0px;
  left: 0px;
}
.verbactiv {
  text-decoration: none;
}

@keyframes move {
  0% {
    left: -300px;
  }
  10% {
    left: -270px;
  }
  20% {
    left: -240px;
  }
  30% {
    left: -210px;
  }
  40% {
    left: -180px;
  }
  50% {
    left: -150px;
  }
  60% {
    left: -120px;
  }
  70% {
    left: -90px;
  }
  80% {
    left: -60px;
  }
  90% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
.button {
  width: calc(98% - 20px);
  height: auto;
  margin: 10px;
  padding: 10px;
  background-color: rgba(101, 171, 185, 0.991);
  border-radius: 40px;
  border-color: darkturquoise;
  border-style: solid;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 20px;
  font-weight: 900;
}

.buttonactive {
  width: calc(98% - 20px);
  height: auto;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 20px;
  font-weight: 900;
  margin: 10px;
  padding: 10px;
  background-color: rgba(7, 109, 129, 0.991);
  border-radius: 40px;
  border-color: rgb(7, 94, 95);
  border-style: solid;
  box-shadow: 10px 10px 10px rgb(7, 196, 186);
  color: rgb(7, 196, 186);
}

@media screen and (max-width: 800px) {
  .but {
    font-size: 25px;
  }

  .grammatica_1 {
    border-radius: 20px;
    font-size: 25px;
  }
  .but_2 {
    width: 100px;
    height: 40px;
    margin: 5px;
    padding: 5px;
    border-radius: 20px;
    font-size: 20px;
    border-width: 5px;
  }
}

@media screen and (max-width: 600px) {
  .grammatica_1 {
    margin: 5px;
    padding: 0;
    width: 95%;
    border-radius: 30px;
    grid-template-columns: 20% 50% 25%;
    grid-template-rows: repeat(7, max-content);
    font-size: 20px;
  }
}
