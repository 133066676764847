.list_team {
  margin: auto;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.list_item {
  margin: 10px;
  margin-left: 20px;
  padding: 10px;
  width: 100%;
  font-family: MonsL, Tahoma, Verdana, sans-serif;
  font-size: 20px;
  color: rgb(26, 27, 27);
  background-color: rgba(203, 235, 235, 1);
}
.list_item:hover {
  background-color: aquamarine;
}

.list {
  margin: 0;
  padding: 20px;
  width: 40%;
  height: calc(100vh - 20px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: MonsL, Tahoma, Verdana, sans-serif;
  font-size: 20px;
  color: rgb(26, 27, 27);
  background-color: rgba(203, 235, 235, 1);
  overflow: scroll;
  overflow-x: hidden;
}
.list::-webkit-scrollbar-track:vertical {
  background-color: transparent;
}

@media screen and (max-width: 900px) {
  .list_item {
    margin-left: 10px;
    font-size: 15px;
  }
}
