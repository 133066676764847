.main {
  margin: 20px;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
}

.image {
  margin: 3px;
  padding: 0;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  object-fit: cover;
  overflow: hidden;
}
.image:hover {
  box-shadow: 10px 10px 10px rgb(139, 219, 213);
}

.text {
  margin: 0;
  padding: 0;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 15px;
  text-align: left;
  color: aquamarine;
  text-decoration: none;
  text-align: center;
}

.group {
  margin: 5px;
  padding: 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  border-width: 3px;
  border-color: aquamarine;
  cursor: pointer;
  background-color: rgba(4, 50, 57, 0.991);
}
.group:hover {
  box-shadow: 10px 10px 10px rgb(139, 219, 213);
}
