.main {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 96%;
  display: flex;
  flex-direction: column;
  z-index: 0;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
}

.list {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  overflow: scroll;
  overflow-x: hidden;
  z-index: 2;
}

@media screen and (max-width: 750px) {
  .main {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
}
