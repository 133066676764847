.main {
  width: 100%;
  margin: auto;
  margin-top: 20px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.imgblock {
  width: 40vw;
  height: 30vh;
  background: transparent;
  padding: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 60;
  object-fit: cover;
  position: relative;
}
.deck {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.frame {
  margin: 0;
  width: 700px;
  height: 870px;
  position: absolute;
  right: 20px;
  top: 100px;
  overflow: hidden;
  z-index: 105;
  box-shadow: 10px 10px 10px gray;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 40px;
  background-color: aquamarine;
}
.iframe {
  margin: 0;
  padding: 0;
  width: 840px;
  height: 1000px;
  border-radius: 40px;
  overflow: hidden;
}

.image {
  margin: auto;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(137, 230, 199);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 60;
  border-radius: 50px;
  box-shadow: 10px 10px 10px gray;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 30px;
  text-align: center;
  color: #0c3b14;
  user-select: none;
}
.link {
  margin: 10px;
  padding: 10px;
  width: 80%;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 20px;
  text-align: center;
  color: aquamarine;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}
.frameb {
  margin: 10px;
  padding: 10px;
  width: 80%;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 20px;
  text-align: center;
  color: aquamarine;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}

.res {
  margin: 20px;
  padding: 0;
  width: 40vw;
  height: 100px;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 25px;
  text-align: center;
  color: #0c3b14;
  text-decoration: none;
  background-color: rgb(137, 230, 199);
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.input {
  margin: 0;
  margin-left: 20px;
  height: 40px;
  padding: 0;
  width: 60%;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 20px;
  text-align: center;
  color: #0c3b14;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
}
.button {
  margin: 20px;
  margin-right: 12px;
  width: 30%;
  height: 80px;
  padding: 0;
  background-color: rgba(4, 50, 57, 0.991);
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 20px;
  text-align: center;
  color: rgb(137, 230, 199);
  text-decoration: none;
  border-radius: 100px;
  border-color: transparent;
  align-items: center;
  cursor: pointer;
}
.button2 {
  margin: 10px;

  width: 100%;
  height: 80px;
  padding: 0;
  background-color: rgba(4, 50, 57, 0.991);
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 15px;
  text-align: center;
  color: rgb(137, 230, 199);
  text-decoration: none;
  border-radius: 100px;
  border-color: transparent;
  align-items: center;
  cursor: pointer;
}
.button3 {
  margin: 10px;
  width: 100%;
  height: 80px;
  padding: 0;
  background-color: rgba(210, 235, 143, 0.991);
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 15px;
  text-align: center;
  color: rgb(13, 15, 15);
  text-decoration: none;
  border-radius: 100px;
  border-color: transparent;
  align-items: center;
  cursor: pointer;
}
.texter {
  font-size: 15px;
}
@media screen and (max-width: 1200px) {
  .button2 {
    margin: 5px;
    width: 100%;
    height: 80px;
    padding: 0;
    font-size: 10px;
  }
  .button3 {
    margin: 5px;
    width: 100%;
    height: 80px;
    padding: 0;
    font-size: 10px;
  }
  .frame {
    width: 400px;
    height: 870px;
  }
  .iframe {
    width: 400px;
    height: 1000px;
  }
}

@media screen and (max-width: 1000px) {
  .imgblock {
    width: 70vw;
  }
}

@media screen and (max-width: 1001px) {
  .imgblock {
    width: 90vw;
  }
  .image {
    font-size: 20px;
  }
  .res {
    width: 80vw;
    font-size: 20px;
  }
  .frame {
    width: 200px;
    height: 870px;
  }
  .iframe {
    width: 300px;
    height: 1000px;
  }
}
