.main {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 10;
  overflow-x: hidden;
  background-color: rgb(230, 226, 205);
}
.block {
  margin: auto;
  padding: 0;
  margin-top: 20px;
  width: 100%;
  max-width: 1800px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 10;
}

.header {
  width: 100%;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
}
.grup {
  width: 100%;
  margin: auto;
  margin-top: 100px;
  padding: 0;
  display: grid;
  flex-direction: row;
  justify-content: flex-start;
  align-self: center;
  grid-template-columns: repeat(auto-fill, 420px);
  justify-content: center;
}

.preview_2 {
  width: calc(100vw * 0.4);
  height: calc(100vh * 0.8);
  margin: 10px;
  background-color: rgba(21, 117, 136, 0.991);
  border-radius: calc(100vw * 0.4);
  position: absolute;
  overflow: hidden;
  display: flex;
  z-index: -5;
}
.preview {
  width: calc(100vw * 0.4);
  height: calc(100vw * 0.4);
  margin: 10px;
  border-radius: calc(100vw * 0.4);
  position: absolute;
  display: flex;
}
.activet {
  width: calc(100vw * 0.15);
  height: calc(100vw * 0.15);
  margin: 0;
  padding: 0;
  border-radius: calc(100vw * 0.2);
  border-color: aqua;
  border-width: 3px;
  border-style: solid;
  position: absolute;
  background-color: rgba(21, 117, 136, 0.991);
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  box-shadow: 10px 10px 10px rgb(7, 196, 186);
  color: rgb(7, 196, 186);
  text-decoration: none;
  right: 20px;
  top: 20px;
}

.activet_2 {
  width: calc(100vw * 0.1);
  height: calc(100vw * 0.1);
  margin: 0;
  padding: 0;
  border-radius: calc(100vw * 0.1);
  border-color: aqua;
  border-width: 3px;
  border-style: solid;
  position: absolute;
  background-color: rgba(21, 117, 136, 0.991);
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  box-shadow: 10px 10px 10px rgb(7, 196, 186);
  color: rgb(7, 196, 186);
  text-decoration: none;
  right: 20%;
  bottom: 1%;
}
.textt_2 {
  margin: 0;
  padding: 0;
  width: calc(100vw * 0.1);
  height: calc(100vw * 0.1);
  font-family: Tahoma, Verdana, sans-serif;
  font-size: calc(100vw * 0.012);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: transparent;
}
.textt {
  margin: 0;
  padding: 10px;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: calc(100vw * 0.015);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: transparent;
}

.text {
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 20px;
  background-color: transparent;
  color: rgb(7, 196, 186);
  margin: 30px;
  text-align: center;
}
