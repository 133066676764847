.main {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(4, 50, 57, 0.991);
}
.masseges {
  margin: auto;
  padding: 10px;
  width: 90%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(4, 50, 57, 0.991);
  overflow: scroll;
  overflow-x: hidden;
}
.form {
  margin: 20px;
  padding: 10px;
  width: 95%;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.active {
  width: 90%;
  height: auto;
  min-height: 50px;
  margin: 5px;
  padding: 20px;
  background-color: rgba(101, 171, 185, 0.991);
  border-radius: 60px;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 25px;
  font-weight: 900;
  color: rgba(4, 50, 57, 0.991);
  border-width: 5px;
  border-color: aquamarine;
  border-style: solid;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-style: italic;
}
.message {
  width: 98%;
  height: auto;
  margin: 5px;
  padding: 5px;
  background-color: rgba(224, 223, 162, 0.991);
  border-radius: 20px;
  border-width: 5px;
  border-color: rgb(228, 177, 9);
  border-style: solid;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.text {
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 25px;
  font-weight: 900;
  font-style: italic;
  color: rgba(4, 50, 57, 0.991);
}
.user {
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 20px;
  font-weight: 900;
  color: rgba(136, 79, 4, 0.991);
}
.button {
  width: 200px;
  height: auto;
  margin: 5px;
  padding: 5px;
  background-color: rgba(101, 171, 185, 0.991);
  border-radius: 60px;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 25px;
  font-weight: 900;
  color: rgba(4, 50, 57, 0.991);
  border-width: 5px;
  border-color: aquamarine;
  border-style: solid;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 10px 10px 10px rgb(7, 196, 186);
}
