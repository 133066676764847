.main {
  width: 100%;
  margin: 0;
  padding: 0;
}
.form {
  margin: 0;
  padding: 0;
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
}

.buttonbase {
  border-style: solid;
  z-index: 0;
  justify-content: center;
  background-color: rgb(105, 181, 222);
  border-color: rgb(114, 141, 223);
  color: rgba(4, 50, 57, 0.991);
  text-align: center;
}

.button {
  margin: 20px;
  padding: 10px;
  width: 90%;
  height: auto;
  border-radius: calc(100vw * 0.05);
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 30px;
  font-weight: 900;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.buttons {
  border-style: solid;
  z-index: 0;
  justify-content: center;
  background-color: rgb(105, 181, 222);
  border-color: rgb(114, 141, 223);
  color: rgba(4, 50, 57, 0.991);
  text-align: center;
  border-width: 10px;
  border-color: aquamarine;
  border-style: solid;
}

.list {
  margin: 30px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: left;
  color: aqua;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 20px;
  font-weight: 900;
}

@media screen and (max-height: 600px) {
  .form {
    flex-direction: row;
    justify-content: left;
    align-items: center;
  }

  .button {
    margin: 5px;
    padding: 5px;
    width: 50%;
    font-size: 15px;
    border-width: 5px;
  }

  .buttons {
    border-style: solid;
    z-index: 0;
    justify-content: center;
    background-color: rgb(105, 181, 222);
    border-color: rgb(114, 141, 223);
    color: rgba(4, 50, 57, 0.991);
    text-align: center;
    border-width: 5px;
    border-color: aquamarine;
    border-style: solid;
  }

  .list {
    margin: 10px;

    width: 100%;
    font-size: 20px;
    font-weight: 900;
  }
}
