.main {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
}

.title {
  margin: 10px;
  padding: 2px;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 25px;
  font-weight: 900;
  color: aqua;
}

@media screen and (max-width: 600px) {
  .title {
    margin: 5px;
    padding: 2px;

    font-size: 15px;
  }
}
