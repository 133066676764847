.main {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
}
.butV {
  text-decoration: none;
}
.but_t {
  text-decoration: none;
}
.verbactiv {
  text-decoration: none;
}

.block {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.but {
  animation: glowing 1300ms infinite;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: calc(100vw * 0.025);
  font-weight: 900;
}

.but_2 {
  width: 150px;
  height: 60px;
  margin: 5px;
  padding: 5px;
  background-color: rgba(221, 146, 8, 0.991);
  border-radius: 60px;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 25px;
  font-weight: 900;
  color: rgba(4, 50, 57, 0.991);
  border-width: 5px;
  border-color: rgb(255, 210, 127);
  border-style: solid;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 5px rgb(228, 186, 70);
}

@keyframes glowing {
  0% {
    background-color: #2ba805;
    box-shadow: 0 0 3px #2ba805;
  }
  50% {
    background-color: #49e819;
    box-shadow: 0 0 10px #49e819;
  }
  100% {
    background-color: #2ba805;
    box-shadow: 0 0 3px #2ba805;
  }
}

.preview {
  width: calc(100vw * 0.06);
  height: calc(100vw * 0.06);
  margin-left: 10px;
  background-color: rgba(21, 117, 136, 0.991);
  border-radius: calc(100vw * 0.06);
  border-color: aquamarine;
  border-width: 8px;
  border-style: solid;
  overflow: hidden;
  display: flex;
  position: absolute;
  top: calc(100vw * -0.01);
  left: calc(100vw * 0.27);
}

.grammatica_1 {
  margin: 5px;
  margin-top: 5px;
  padding: 10px;
  padding-right: 12px;
  height: auto;
  width: calc(100vw * 0.89);
  border-radius: 40px;
  border-width: 5px;
  border-color: aquamarine;
  border-style: solid;
  display: grid;
  grid-template-columns: calc(100vw * 0.55) calc(100vw * 0.3);
  grid-template-rows: repeat(6, auto);
  font-family: Tahoma, Verdana, sans-serif;
  font-size: calc(100vw * 0.023);
  font-weight: 900;
}

.shab {
  margin: calc(100vw * 0.006);
  padding: calc(100vw * 0.004);
  width: 100%;
  height: auto;
  min-height: 40px;
  background-color: rgba(101, 171, 185, 0.991);
  border-radius: 50px;
  border-width: 5px;
  border-color: aquamarine;
  border-style: solid;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: rgba(4, 50, 57, 0.991);
  cursor: pointer;
}

.verbo:hover {
  background-color: rgb(5, 58, 87);
  box-shadow: 10px 10px 10px rgb(7, 196, 186);
  color: rgb(7, 196, 186);
}
.verbo:active {
  background-color: rgb(5, 58, 87);
  box-shadow: 10px 10px 10px rgb(7, 196, 186);
  color: rgb(7, 196, 186);
}

.verbo {
  font-family: Tahoma, Verdana, sans-serif;
  font-size: calc(100vw * 0.02);
  font-weight: 900;
}

.pr {
  background-color: rgba(209, 124, 198, 0.991);
  position: relative;
  color: rgba(4, 50, 57, 0.991);
  justify-content: center;
}

.prn {
  background-color: rgba(209, 124, 198, 0.991);
  position: relative;
  color: rgba(209, 15, 15, 0.991);
  font-size: calc(100vw * 0.018);
}

.tl {
  background-color: rgba(228, 160, 35, 0.991);
  width: calc(100vw * 0.1);
}

.link {
  background-color: rgba(228, 160, 35, 0.991);
  text-decoration: none;
}
.link:hover {
  background-color: rgb(5, 58, 87);
  box-shadow: 10px 10px 10px rgb(7, 196, 186);
  color: rgb(7, 196, 186);
}
.list {
  width: 300px;
  height: 100%;
  display: flex;
  position: fixed;
  margin-right: 10px;
  margin: 0;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: scroll;
  overflow-x: hidden;
  background-color: rgba(4, 50, 57, 0.991);
  animation-name: move;
  animation-duration: 0.3s;
  z-index: 105;
  top: 0px;
  left: 0px;
}

@keyframes move {
  0% {
    left: -300px;
  }
  10% {
    left: -270px;
  }
  20% {
    left: -240px;
  }
  30% {
    left: -210px;
  }
  40% {
    left: -180px;
  }
  50% {
    left: -150px;
  }
  60% {
    left: -120px;
  }
  70% {
    left: -90px;
  }
  80% {
    left: -60px;
  }
  90% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
.button {
  width: calc(98% - 20px);
  height: auto;
  margin: 10px;
  padding: 10px;
  background-color: rgba(101, 171, 185, 0.991);
  border-radius: 40px;
  border-color: darkturquoise;
  border-style: solid;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 20px;
  font-weight: 900;
}

.buttonactive {
  width: calc(98% - 20px);
  height: auto;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 20px;
  font-weight: 900;
  margin: 10px;
  padding: 10px;
  background-color: rgba(7, 109, 129, 0.991);
  border-radius: 40px;
  border-color: rgb(7, 94, 95);
  border-style: solid;
  box-shadow: 10px 10px 10px rgb(7, 196, 186);
  color: rgb(7, 196, 186);
}

@media screen and (max-width: 600px) {
  .grammatica_1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  }
  .shab {
    min-height: 20px;
  }
  .verbo {
    font-size: 18px;
  }
  .but {
    font-size: 20px;
  }

  .tl {
    font-size: 20px;
  }
  .preview {
    width: calc(100vw * 0.11);
    height: calc(100vw * 0.11);
    margin-left: 10px;
    background-color: rgba(21, 117, 136, 0.991);
    border-radius: calc(100vw * 0.1);
    border-width: 2px;
    border-style: solid;
    overflow: hidden;
    display: flex;
    position: absolute;
    top: calc(100vw * -0.01);
    left: calc(100vw * 0.78);
  }
  .but_2 {
    width: 100px;
    height: auto;
    margin: 5px;
    padding: 5px;
    background-color: rgba(221, 146, 8, 0.991);
    border-radius: 40px;
    font-family: Tahoma, Verdana, sans-serif;
    font-size: 15px;

    color: rgba(4, 50, 57, 0.991);
    border-width: 5px;
  }
  .prn {
    background-color: rgba(209, 124, 198, 0.991);
    position: relative;
    color: rgba(209, 15, 15, 0.991);
    font-size: 15px;
  }
}
