.main {
  width: 300px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  position: relative;
}
.imageP {
  margin: 0;
  padding: 0;
  padding: 2px;
  width: 18px;
  height: 18px;
}
.link {
  margin: 2px;
  padding: 2px;
  width: 130px;
  height: auto;
  border-radius: calc(100vw * 0.05);
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 10px;
  font-weight: 900;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 0;
  color: rgba(4, 50, 57, 0.991);
  border-style: solid;
  background-color: rgb(223, 123, 8);
  text-align: center;
  border-width: 5px;
  border-color: rgb(199, 99, 6);
  border-style: solid;
  text-decoration: none;
  position: fixed;
  bottom: 10px;
  right: 10px;
  box-shadow: 10px 10px 10px rgb(218, 142, 71);
}

@media screen and (max-width: 1500px) {
}
@media screen and (max-width: 1000px) {
}

@media screen and (max-width: 650px) {
}

@media screen and (max-width: 500px) {
}
