.main {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
}

.active {
  height: auto;
  margin: 5px;
  padding: 5px;
  background-color: rgba(101, 171, 185, 0.991);
  border-radius: 60px;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 25px;
  font-weight: 900;
  color: rgba(4, 50, 57, 0.991);
  border-width: 5px;
  border-color: aquamarine;
  border-style: solid;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.text {
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 25px;
  font-weight: 900;
  color: rgba(4, 50, 57, 0.991);
  padding-left: 30px;
}

.team {
  margin: 10px;
  padding: 10px;
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  border-width: 5px;
  border-color: aquamarine;
  border-style: solid;
  border-radius: 35px;
}

@media screen and (max-width: 600px) {
  .text {
    font-size: 15px;
    font-weight: 900;
    color: rgba(4, 50, 57, 0.991);
    padding-left: 15px;
  }

  .active {
    border-width: 4px;
  }
}

@media screen and (max-width: 450px) {
  .team {
    margin: 5px;
    padding: 5px;
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
}
