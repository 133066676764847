.main {
  margin: 20px;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}
.card {
  margin: 20px;
  padding: 0;
  padding-top: 20px;
  width: 320px;
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 2px 5.98px 0px rgba(8, 8, 8, 0.15);
  background-color: rgba(228, 219, 173, 0.6);
  border-radius: 10px;
  text-decoration: none;
}
.card:hover {
  box-shadow: 0px 10px 8px 0px rgb(226, 201, 87);
  color: rgb(34, 109, 105);
}
.image {
  margin: 40px;
  padding: 0;
  width: 280px;
  height: 400px;
  border-radius: 124px;
  object-fit: cover;
  overflow: hidden;
}

.text {
  margin: 0;
  padding: 0;

  font-family: MonsB, Tahoma, Verdana, sans-serif;
  font-size: 25px;
  font-weight: 400;
  text-align: left;
  color: rgb(95, 74, 3);
  text-decoration: none;
  text-align: center;
}
.menu {
  margin: 0;
  padding: 0;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.group {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}
.children {
  width: 280px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 15px;
  border-radius: 100px;
  border-color: transparent;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 15px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  box-shadow: 0px 2px 5.98px 0px rgba(8, 8, 8, 0.15);
  text-decoration: none;
  color: rgb(5, 43, 41);
  cursor: pointer;
  background-color: rgb(230, 226, 205);
}
.children:hover {
  box-shadow: 0px 10px 8px 0px rgb(226, 201, 87);
  color: rgb(34, 109, 105);
}
