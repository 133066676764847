.main {
  margin: auto;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow-x: hidden;
}

.list {
  width: 80%;
  max-width: 800px;
  display: flex;
  margin: auto;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow-x: hidden;
  z-index: 2;
}
