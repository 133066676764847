.button {
  margin: 10px;
  padding: 5px;
  width: 87%;
  background-color: rgb(195, 218, 231);
  color: rgba(4, 50, 57, 0.991);
  text-align: center;
  border-radius: 40px;
  box-shadow: 0px 0px 5.98px 5px rgba(8, 8, 8, 0.15);
  border-color: transparent;
  cursor: pointer;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 20px;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}
.button:hover {
  box-shadow: 0px 0px 5.98px 5px rgba(134, 245, 239, 0.15);
  background-color: rgb(157, 238, 238);
  color: rgba(3, 76, 87, 0.991);
}

@media screen and (max-width: 900px) {
  .button {
    font-size: 15px;
  }
}
