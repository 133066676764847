.main {
  margin: auto;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.text {
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 20px;
  text-align: center;
  color: rgb(137, 230, 199);
}
.button {
  width: 200px;
  height: auto;
  margin: 0;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-color: transparent;
  background-color: transparent;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 20px;
  text-align: center;
  color: rgb(137, 230, 199);
}
.res {
  margin: 20px;
  margin-left: 30px;
  padding: 0;
  padding-left: 30px;
  width: 40%;
  height: 100px;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 25px;
  text-align: center;
  color: #0c3b14;
  text-decoration: none;
  background-color: rgb(137, 230, 199);
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.block {
  margin: 0;
  padding: 0;
  width: 100%;
  text-decoration: none;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header {
  margin: auto;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 50;
}

.list {
  margin: 0;
  padding: 0;
  width: 80%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 50;
  background-color: transparent;
}

@media screen and (max-width: 900px) {
  .list {
    margin: 0;
    padding: 0;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 50;
    background-color: transparent;
  }
  .res {
    margin: 10px;
    padding: 0;
    padding-left: 20px;
    width: 70%;
    height: 100px;
    font-size: 15px;
  }
}
@media screen and (max-width: 550px) {
  .res {
    margin: 10px;
    padding: 0;
    padding-left: 20px;
    width: 80%;
  }
}
