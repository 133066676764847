.main {
  width: 100%;
  margin: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 10;
}
.imgblock {
  width: 50vw;
  height: 50vh;
  background: transparent;
  padding: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 60;
  object-fit: cover;
  position: relative;
}

.image {
  margin: auto;
  padding: 20px;
  width: 100%;
  height: 95%;
  background-color: rgb(137, 230, 199);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 60;
  border-radius: 50px;
  box-shadow: 10px 10px 10px gray;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 40px;
  text-align: center;
  color: #0c3b14;
}

@media screen and (max-width: 1000px) {
  .imgblock {
    width: 70vw;
    height: 50vh;
  }
}

@media screen and (max-width: 750px) {
  .imgblock {
    width: 90vw;
    height: 50vh;
  }
  .image {
    font-size: 40px;
  }
}
