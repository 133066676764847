.resetPassword {
    margin: 0;
    width: 100%;
    height: 575px;
    margin-top: 80px;
    padding: 0;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
background-color: rgba(28, 28, 33, 1);
  }
  
  .userForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: right;
  }
  