.main {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.menu {
  margin: auto;
  padding: 0;
  width: 100%;
  height: auto;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: rgba(228, 195, 107, 1);
  top: 10vh;
  right: 7vh;
  box-shadow: 10px 10px 10px gray;
}

.icon {
  margin: 5px;
  padding: 0;
  height: 80px;
  width: 80px;
  z-index: 0;
  border-radius: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-width: 10px;
  border-color: aquamarine;
  border-style: solid;
  z-index: 51;
  position: absolute;
  top: 5px;
  right: 5px;
}
.icon:hover {
  box-shadow: 5px 5px 5px rgb(7, 196, 186);
}

@media screen and (max-width: 850px) {
  .menuButton {
    padding: 5px;
    font-size: 15px;
    font-weight: 900;
  }
  .menuitem {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px;
  }
  .icon {
    border-width: 7px;
  }
  .menu {
    width: 300px;
    right: 5vh;
  }
  .menubutton {
    font-size: 20px;
  }
}
@media screen and (max-width: 500px) {
  .icon {
    margin: 2px;
    height: 60px;
    width: 60px;
    top: 2px;
  }
}
