.main {
  margin: 20px;
  padding: 10px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: aquamarine;
  border-radius: 40px;
  border-width: 5px;
  border-color: rgb(93, 89, 153);
  border-style: solid;
  box-sizing: border-box;
  position: relative;
}
.p_1 {
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.menuButton {
  margin: 10px;
  padding: 10px;
  height: auto;
  border-radius: 60px;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 30px;
  font-weight: 900;
  color: rgba(4, 50, 57, 0.991);
  border-width: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: aquamarine;
  border: none;
}

.menuButton_1 {
  color: aquamarine;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.menuButton_3 {
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 25px;
  font-weight: 300;
  color: rgba(4, 50, 57, 0.991);
  font-style: italic;
}

.menuButton_4 {
  font-family: Tahoma, Verdana, sans-serif;
  padding: 0;
  margin: 0;
  height: 25px;
  font-size: 25px;
  font-weight: 300;
  color: rgba(224, 55, 4, 0.991);
  font-style: italic;
}
.menuButton_11 {
  border-radius: 40px;
  border-width: 3px;
  border-color: rgb(93, 89, 153);
  border-style: solid;
}

.menuitem {
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0;
  width: 100%;
  height: auto;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 21px;
  font-weight: 900;
  color: rgba(4, 50, 57, 0.991);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.menu {
  margin: auto;
  padding: 0;
  width: 96%;
  height: auto;
  border-radius: 40px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
}

.menulist {
  margin: 0;
  padding: 0;
  width: 96%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  list-style-type: none;
}
.preview {
  width: calc(100vw * 0.1);
  height: calc(100vw * 0.1);
  margin-left: 10px;
  background-color: rgba(21, 117, 136, 0.991);
  border-radius: calc(100vw * 0.06);
  border-color: rgb(93, 89, 153);
  border-width: 8px;
  border-style: solid;
  overflow: hidden;
  display: flex;
  position: absolute;
  bottom: -10px;
  right: -10px;
}
.but {
  width: 200px;
  height: 60px;
  margin: 5px;
  padding: 5px;
  background-color: rgba(221, 146, 8, 0.991);
  border-radius: 60px;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 25px;
  font-weight: 900;
  color: rgba(4, 50, 57, 0.991);
  border-width: 10px;
  border-color: rgb(255, 210, 127);
  border-style: solid;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 5px rgb(228, 186, 70);
}

@media screen and (max-width: 1000px) {
  .menuButton {
    padding: 10px;
    font-size: 20px;
    border-width: 5px;
    min-height: 20px;
  }
  .menuButton_3 {
    font-size: 20px;
  }

  .menuButton_4 {
    font-size: 20px;
    height: 20px;
  }
  .menuButton_2 {
    width: 200px;
  }
}

@media screen and (max-width: 600px) {
  .p_1 {
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .but {
    width: 100px;
    height: auto;
    margin: 5px;
    padding: 5px;
    border-radius: 20px;
    font-size: 15px;
    border-width: 5px;
  }
  .preview {
    border-width: 3px;
  }
}

@media screen and (max-width: 400px) {
  .menuButton {
    padding: 10px;
    font-size: 15px;
    border-width: 5px;
    min-height: 15px;
  }
  .menuButton_3 {
    font-size: 15px;
  }

  .menuButton_4 {
    font-size: 15px;
    height: 15px;
  }
  .menuButton_2 {
    width: 80px;
  }
  .p_1 {
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .preview {
    border-width: 3px;
  }
}
