.main {
  width: 100%;
  margin: 0;
  margin-top: 200px;
  padding: 0;
  height: 93%;
  overflow-x: hidden;
}
.menuf {
  display: flex;
}
.header {
  margin: auto;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 50;
}
.festlist {
  margin: 0;
  margin-left: 20px;
  width: 95%;
  min-width: 100px;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  background-color: rgba(4, 50, 57, 0.991);
}

.list {
  width: 300px;
  height: 100%;
  display: flex;
  position: fixed;
  margin-right: 10px;
  margin: 0;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: scroll;
  overflow-x: hidden;
  background-color: rgba(4, 50, 57, 0.991);
  animation-name: move;
  animation-duration: 0.3s;
  z-index: 100;
  top: 0px;
  left: 0px;
}

.list_2 {
  width: 320px;
  height: 100%;
  display: flex;
  position: fixed;
  left: 300px;
  margin: 0;
  padding: 0;

  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: scroll;
  overflow-x: hidden;
  background-color: rgba(4, 50, 57, 0.991);
  animation-name: move_2;
  animation-duration: 0.3s;
  z-index: 101;
  top: 0;
}

@keyframes move {
  0% {
    left: -300px;
  }
  10% {
    left: -270px;
  }
  20% {
    left: -240px;
  }
  30% {
    left: -210px;
  }
  40% {
    left: -180px;
  }
  50% {
    left: -150px;
  }
  60% {
    left: -120px;
  }
  70% {
    left: -90px;
  }
  80% {
    left: -60px;
  }
  90% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}

@keyframes move_2 {
  0% {
    left: 30;
  }
  10% {
    left: 60px;
  }
  20% {
    left: 90px;
  }
  30% {
    left: 120px;
  }
  40% {
    left: 150px;
  }
  50% {
    left: 180px;
  }
  60% {
    left: 210px;
  }
  70% {
    left: 240px;
  }
  80% {
    left: 270px;
  }

  90% {
    left: 300px;
  }
  100% {
    left: 310px;
  }
}
.buttonteam {
  width: calc(100vw * 0.4);
  height: auto;
  margin: 20px;
  padding: 10px;
  background-color: rgba(101, 171, 185, 0.991);
  border-radius: 80px;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 30px;
  font-weight: 900;
  color: rgba(4, 50, 57, 0.991);
  border-width: 10px;
  border-color: aquamarine;
  border-style: solid;
  align-self: center;
}
.button {
  width: calc(98% - 20px);
  height: auto;
  margin: 10px;
  padding: 10px;
  background-color: rgba(101, 171, 185, 0.991);
  border-radius: 40px;
  border-color: darkturquoise;
  border-style: solid;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 20px;
  font-weight: 900;
}

.buttonactive {
  width: calc(98% - 20px);
  height: auto;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 20px;
  font-weight: 900;
  margin: 10px;
  padding: 10px;
  background-color: rgba(7, 109, 129, 0.991);
  border-radius: 40px;
  border-color: rgb(7, 94, 95);
  border-style: solid;
  box-shadow: 10px 10px 10px rgb(7, 196, 186);
  color: rgb(7, 196, 186);
}

@media screen and (max-width: 700px) {
  .buttonteam {
    width: calc(100vw * 0.8);
    margin: 3px;
    padding: 3px;
    font-size: 30px;
    border-width: 5px;
  }

  .list {
    width: 200px;
    margin: 0;
    padding: 0;
  }

  .list_2 {
    width: 200px;
    left: 200px;
  }

  @keyframes move {
    0% {
      left: -200px;
    }
    10% {
      left: -180px;
    }
    20% {
      left: -160px;
    }
    30% {
      left: -140px;
    }
    40% {
      left: -120px;
    }
    50% {
      left: -100px;
    }
    60% {
      left: -80px;
    }
    70% {
      left: -60px;
    }
    80% {
      left: -40px;
    }
    90% {
      left: -20px;
    }
    100% {
      left: 0px;
    }
  }

  @keyframes move_2 {
    0% {
      left: 20;
    }
    10% {
      left: 40px;
    }
    20% {
      left: 60px;
    }
    30% {
      left: 80px;
    }
    40% {
      left: 100px;
    }
    50% {
      left: 120px;
    }
    60% {
      left: 140px;
    }
    70% {
      left: 160px;
    }
    80% {
      left: 180px;
    }
    90% {
      left: 200px;
    }
    100% {
      left: 210px;
    }
  }

  .button {
    margin: 5px;
    padding: 5px;
    font-size: 15px;
  }

  .buttonactive {
    margin: 5px;
    padding: 5px;
    font-size: 15px;
  }
}

@media screen and (max-width: 400px) {
  .buttonteam {
    width: calc(100vw * 0.8);
    font-size: 20px;
  }

  .list {
    width: 160px;
    margin: 0;
    padding: 0;
  }

  .list_2 {
    width: 160px;
    left: 160px;
  }

  @keyframes move {
    0% {
      left: -160px;
    }
    10% {
      left: -140px;
    }
    20% {
      left: -120px;
    }
    30% {
      left: -100px;
    }
    40% {
      left: -80px;
    }
    50% {
      left: -60px;
    }
    60% {
      left: -50px;
    }
    70% {
      left: -40px;
    }
    80% {
      left: -30px;
    }
    90% {
      left: -20px;
    }
    100% {
      left: 0px;
    }
  }

  @keyframes move_2 {
    0% {
      left: 20px;
    }
    10% {
      left: 30px;
    }
    20% {
      left: 40px;
    }
    30% {
      left: 50px;
    }
    40% {
      left: 60px;
    }
    50% {
      left: 80px;
    }
    60% {
      left: 100px;
    }
    70% {
      left: 120px;
    }
    80% {
      left: 140px;
    }
    90% {
      left: 150px;
    }
    100% {
      left: 160px;
    }
  }

  .button {
    margin: 5px;
    padding: 5px;
    font-size: 12px;
  }

  .buttonactive {
    margin: 5px;
    padding: 5px;
    font-size: 12px;
  }
}
