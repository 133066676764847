.login {
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 600px;
}
.imageP {
  margin: 0;
  padding: 2px;
  width: 35px;
  height: 35px;
}
.main {
  margin: 10px;
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.form {
  margin: 0;
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 15%;
}

.text {
  margin: 10px;
  padding: 10px;
  width: 100%;
  height: auto;
  border-radius: calc(100vw * 0.05);
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 20px;
  font-weight: 900;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 0;
  color: rgba(4, 50, 57, 0.991);
  border-style: solid;
  background-color: rgb(105, 181, 222);
  text-align: center;
  border-width: 5px;
  border-color: aquamarine;
  border-style: solid;
  text-decoration: none;
  box-shadow: 10px 10px 10px rgb(7, 196, 186);
}

.link_b {
  border-color: rgb(128, 63, 3);
  background-color: rgb(218, 142, 71);
  box-shadow: 10px 10px 10px rgb(218, 142, 71);
}
.preview_2 {
  width: 90%;
  height: 90%;
  margin: 10px;
  background-color: rgba(21, 117, 136, 0.991);
  border-radius: calc(100vw * 0.4);
  box-shadow: 10px 10px 10px rgb(7, 196, 186);
  color: rgb(7, 196, 186);
  border-color: aqua;
  border-width: 3px;
  border-style: solid;
  overflow: hidden;
  display: flex;
  left: 5px;
  top: 5px;
}

.preview {
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
}
.header {
  margin: auto;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 50;
}

@media screen and (max-width: 850px) {
  .main {
    margin: 0;
    margin-top: 0;
    padding: 0;
    padding-top: 0;
    width: 95%;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .form {
    margin: 0;
    width: 85%;
    height: auto;
    right: 10px;
    top: 60%;
  }
  .text {
    border-radius: 40px;
    font-family: Tahoma, Verdana, sans-serif;
    font-size: 15px;
    font-weight: 900;
  }
}
@media screen and (max-width: 555px) {
  .preview {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .form {
    margin: 0;
    width: 85%;
    height: auto;
    left: 20px;
    top: 60%;
    align-self: flex-end;
  }
}
