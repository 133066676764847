.list {
  width: 98%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.button {
  margin: 20px;
  padding: 0;
  width: calc(100% * 0.33);
  height: calc(100vw * 0.06);
  border-radius: calc(100vw * 0.05);
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 40px;
  font-weight: 900;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
}

.buttonw {
  border-style: solid;
  z-index: 0;
  background-color: rgb(105, 181, 222);
  border-color: rgb(114, 141, 223);
  color: rgba(4, 50, 57, 0.991);
  text-align: center;
}

.buttonw:focus {
  background-color: rgb(5, 58, 87);
  box-shadow: 10px 10px 10px rgb(7, 196, 186);
  color: rgb(7, 196, 186);
}

.buttonbase {
  border-style: solid;
  z-index: 0;
  justify-content: center;
  background-color: rgb(105, 181, 222);
  border-color: rgb(114, 141, 223);
  color: rgba(4, 50, 57, 0.991);
  text-align: center;
}

.buttonok {
  padding-left: calc(100vw * 0.05);
  border-style: solid;
  z-index: 0;
  background-color: rgb(105, 181, 222);
  border-color: rgb(114, 141, 223);
  color: rgba(7, 146, 65, 0.991);
}

.buttonno {
  padding-left: 10px;
  border-style: solid;
  z-index: 0;
  background-color: rgb(105, 181, 222);
  border-color: rgb(114, 141, 223);
  color: rgba(207, 16, 9, 0.991);
  text-align: center;
}

.buttonbase:hover {
  background-color: rgb(5, 58, 87);
  box-shadow: 10px 10px 10px rgb(7, 196, 186);
  color: rgb(7, 196, 186);
}

.preview {
  width: calc(100vw * 0.07);
  height: calc(100vw * 0.07);
  margin-left: 10px;
  background-color: rgba(21, 117, 136, 0.991);
  border-radius: calc(100vw * 0.07);
  border-color: aquamarine;
  border-width: 8px;
  border-style: solid;
  overflow: hidden;
  display: flex;
}

@media screen and (max-width: 1420px) {
  .button {
    font-size: 30px;
    margin: 10px;
  }
  .preview {
    width: 80px;
    height: 80px;
    margin-left: 10px;
    border-radius: 80px;
    border-width: 3px;
  }
}
@media screen and (max-width: 1100px) {
  .button {
    font-size: 25px;
  }
  .preview {
    border-width: 3px;
  }
}

@media screen and (max-width: 1000px) {
  .button {
    font-size: 25px;
    width: 95%;
    height: 60px;
    border-radius: 60px;
  }

  .list {
    border-color: aquamarine;
    border-width: 8px;
    border-style: solid;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 40px;
    margin: 5px;
    padding: 5px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
  }
  .preview {
    width: 60px;
    height: 60px;
    border-radius: 60px;
  }
}
