.login {
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 600px;
}
.text {
  margin: 0;
  padding: 0;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 15px;
  font-weight: 900;
  color: rgb(26, 27, 27);
}
.main {
  margin: 5%;
  width: 90%;
  height: 83vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
}

.form {
  margin: 0;
  padding: 0;
  width: 90%;
  max-width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.podval {
  margin: 0;
  width: 50%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.buttonbase {
  margin: 10px;
  padding: 30px;
  width: 80%;
  background-color: rgba(195, 218, 231, 0.6);
  color: rgba(4, 50, 57, 0.991);
  text-align: center;
  border-radius: 60px;
  box-shadow: 0px 0px 5.98px 5px rgba(8, 8, 8, 0.15);
  border-color: transparent;
  cursor: pointer;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 20px;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.buttonbase:is(:-webkit-autofill, :autofill) {
  box-shadow: 0px 0px 5.98px 5px rgba(8, 8, 8, 0.15);
  border-color: transparent;
  background-color: rgb(195, 218, 231);
  color: rgba(240, 243, 243, 0.991);
}

input:-webkit-autofill {
  -webkit-text-fill-color: #31b0dd;
  -webkit-box-shadow: 0 0 0px 40rem #ffff inset;
  -webkit-border-color: transparent;
}
.button {
  margin: 10px;
  padding: 25px;
  width: 87%;
  background-color: rgba(195, 218, 231);
  color: rgba(4, 50, 57, 0.991);
  text-align: center;
  border-radius: 40px;
  box-shadow: 0px 0px 5.98px 5px rgba(8, 8, 8, 0.15);
  border-color: transparent;
  cursor: pointer;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 20px;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.button:hover {
  box-shadow: 0px 0px 5.98px 5px rgba(134, 245, 239, 0.15);
  background-color: rgb(157, 238, 238);
  color: rgba(3, 76, 87, 0.991);
}

.preview_2 {
  width: 90%;
  height: 90vh;
  margin: 20px;
  background-color: rgba(21, 117, 136, 0.991);
  border-radius: calc(100vw * 0.4);
  box-shadow: 10px 10px 10px rgb(7, 196, 186);
  color: rgb(7, 196, 186);
  border-color: aqua;
  border-width: 3px;
  border-style: solid;
  position: absolute;
  overflow: hidden;
  display: flex;
  left: 5px;
  top: 5px;
}

.header {
  margin: auto;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 50;
}
.preview {
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 900px) {
  .preview_2 {
    width: 300px;
    height: 300px;
    border-radius: 300px;
    box-shadow: 10px 10px 10px rgb(7, 196, 186);
    border-color: aqua;
    border-width: 3px;
    border-style: solid;
    position: absolute;
    overflow: hidden;
    display: flex;
    left: 2px;
    top: 30px;
  }
  .main {
    height: 90vh;
  }
  .button {
    padding: 15px;
    font-size: 15px;
  }
  .buttonbase {
    padding: 15px;
    font-size: 15px;
  }
}
