.main {
  width: 100%;
  margin: 0;
  height: 80px;
  margin-bottom: 15px;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background-color: rgba(228, 195, 107, 0.991);
}

.menu {
  margin: 0;
  padding: 0;
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 80;
}
.menul {
  justify-content: flex-start;
  width: 80%;
}

.tit {
  margin: 10px;
  padding: 0;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 25px;
  font-weight: 700;
  color: #be5804;
  align-self: center;
  text-align: center;
}

.preview {
  height: 100px;
  width: 100px;
  z-index: 0;
  background-color: rgba(101, 171, 185, 0.991);
  border-radius: 180px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-width: 5px;
  border-color: aquamarine;
  border-style: solid;
}
.preview:hover {
  box-shadow: 5px 5px 5px rgb(7, 196, 186);
}

@media screen and (max-width: 950px) {
  .preview {
    height: 80px;
    width: 80px;
    border-radius: 60px;
  }

  .main {
    margin-bottom: 5px;
  }

  .menu {
    height: 60px;
  }
  .tit {
    margin-top: 20px;
    font-size: 20px;
  }
}

@media screen and (max-width: 550px) {
  .preview {
    height: 60px;
    width: 60px;
    margin: 2px;
  }
  .main {
    height: 60px;
  }

  .menu {
    height: 50px;
  }
  .tit {
    margin: 5px;

    font-size: 15px;
  }
}
