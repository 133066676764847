.button {
  background-color: aquamarine;
  border-color: darkturquoise;
}

.buttonbase {
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 40px;
  font-weight: 900;
  padding: 10px;
  width: calc(98% - 40px);
  height: auto;
  margin: 20px;
  padding: 10px;
  border-radius: 30px;
  border-style: solid;
  z-index: 0;
}

.buttonm {
  background-color: rgb(105, 181, 222);
  border-color: rgb(114, 141, 223);
}
.buttonm:focus {
  background-color: rgb(5, 58, 87);
  box-shadow: 10px 10px 10px rgb(7, 196, 186);
  color: rgb(7, 196, 186);
}
.buttonf {
  background-color: rgb(229, 171, 124);
  border-color: rgb(207, 32, 163);
}
.buttonf:focus {
  background-color: rgb(126, 5, 110);
  box-shadow: 10px 10px 10px rgb(211, 113, 211);
  color: rgb(211, 113, 211);
}

.buttonru {
  background-color: rgb(192, 212, 9);
  border-color: rgb(185, 245, 4);
}

.button:active {
  border-color: blueviolet;
  background-color: cyan;
}

.button:hover {
  border-color: chartreuse;
  background-color: rgb(229, 188, 208);
}
.buttonru:hover {
  border-color: chartreuse;
  background-color: rgb(223, 187, 205);
}

@media screen and (max-width: 650px) {
  .buttonbase {
    
    font-size: 20px;
    font-weight: 900;
    padding: 10px;
    width: calc(98% - 40px);
    height: auto;
    margin: 10px;
    border-radius: 30px;
     
  }
  


}

@media screen and (max-height: 600px) {
  .buttonbase {
    font-size: 20px;
    font-weight: 900;
    padding: 10px;
    width: calc(98% - 40px);
    height: auto;
    margin: 10px;
    border-radius: 30px;
     
  }
}