.app {
  width: 100vw;
  min-height: 100vh;
  background-color: rgba(10, 110, 126, 0.991);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-x: hidden;
}
