.main {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
}
.menu {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
}
.title {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 5px;
  padding: 5px;
  background-color: transparent;
  font-family: Tahoma, Verdana, sans-serif;
  font-size: 25px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  color: rgba(117, 91, 5, 0.991);
  text-decoration: none;
  cursor: pointer;
  box-sizing: border-box;
}

.children {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 10px;
  font-size: 20px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: rgba(53, 41, 2, 0.991);
  font-family: Tahoma, Verdana, sans-serif;
  background-color: rgb(219, 202, 156);
  box-sizing: content-box;
}

.children:hover {
  background-color: rgb(241, 218, 111);
}

@media screen and (max-width: 1000px) {
  .title {
    font-size: 20px;
  }
  .children {
    font-size: 15px;
  }
}
