.main {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
.preview_2 {
  width: 90vh;
  height: 90vh;
  margin: auto;
  background-color: rgba(21, 117, 136, 0.991);
  border-radius: calc(100vw * 0.4);
  position: absolute;
  overflow: hidden;
  display: flex;
  z-index: -5;
}
.preview {
  width: 90vh;
  height: 90vh;
  border-radius: calc(100vw * 0.4);
  position: absolute;
  display: flex;
}
.activet {
  width: 30vh;
  height: 30vh;
  margin: 0;
  padding: 0;
  border-radius: 30vh;
  position: absolute;
  background-color: rgba(21, 117, 136, 0.991);
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  box-shadow: 0px 0px 20px 10px rgb(7, 196, 186);
  color: rgb(7, 196, 186);
  text-decoration: none;
  right: 20px;
  top: 20px;
}
.activets {
  width: 20vh;
  height: 20vh;
  margin: 0;
  padding: 0;
  border-radius: 30vh;
  position: absolute;
  background-color: rgba(21, 117, 136, 0.991);
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  box-shadow: 0px 0px 20px 10px rgb(7, 196, 186);
  color: rgb(7, 196, 186);
  text-decoration: none;
  right: 5%;
  top: 50%;
}

.activet_2 {
  width: 20vh;
  height: 20vh;
  margin: 0;
  padding: 0;
  border-radius: 20vh;
  position: absolute;
  background-color: rgba(21, 117, 136, 0.991);
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  box-shadow: 0px 0px 20px 10px rgb(7, 196, 186);
  color: rgb(7, 196, 186);
  text-decoration: none;
  right: 20%;
  bottom: 1%;
}

.textt {
  margin: 20px;
  padding: 20px;
  font-family: MonsB, Roboto, Tahoma, Verdana, sans-serif;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: transparent;
  text-decoration: none;
  color: rgb(7, 196, 186);
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .preview_2 {
    width: 90vw;
    height: 90vw;
    border-radius: 90vw;
  }
  .preview {
    width: 90vw;
    height: 90vw;
    border-radius: 90vw;
  }
  .activet {
    width: 30vw;
    height: 30vw;
    border-radius: 30vw;
  }
  .activets {
    width: 20vw;
    height: 20vw;
    border-radius: 30vw;
  }

  .activet_2 {
    width: 20vw;
    height: 20vw;
    border-radius: 20vw;
  }

  .textt {
    margin: 15px;
    padding: 15px;
    font-family: MonsB, Roboto, Tahoma, Verdana, sans-serif;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: transparent;
    text-decoration: none;
  }
}

@media screen and (max-width: 700px) {
  .textt {
    margin: 5px;
    padding: 5px;
    font-family: MonsB, Roboto, Tahoma, Verdana, sans-serif;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: transparent;
  }
}
