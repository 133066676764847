body {
  margin: 0;
  padding: 0;
  background-color: rgba(4, 50, 57, 0.991);
}
@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Medium.ttf"), format("ttf");
}

@font-face {
  font-family: "MonsB";
  src: url("./fonts/MontserratAlternates-Bold.ttf"), format("ttf");
}

@font-face {
  font-family: "MonsL";
  src: url("./fonts/MontserratAlternates-Light.ttf"), format("ttf");
}
