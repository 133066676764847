.main {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
}

.menu {
  margin: 10px;
  padding: 0;
  width: 50%;
  height: 45px;
}
.menu_1 {
  z-index: 24;
}
.menu_2 {
  z-index: 22;
}
.menu_3 {
  z-index: 23;
}

.text_t {
  margin: auto;
  margin-top: 5px;
  padding: 0;
  width: 98%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.team {
  margin: 5px;
  padding: 5px;
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 950px) {
  .menu {
    margin: 5px;
    padding: 0;
    width: 90%;
    height: 50px;
  }
}

@media screen and (max-width: 550px) {
  .menu {
    margin: 5px;
    padding: 0;
    width: 95%;
    height: 35px;
  }
}
